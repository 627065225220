import { useState, useRef, useEffect } from 'react';
import { StyledTable, Thead, Tr, Th, Tbody, EmptyMessage, Button } from '../Table.styles';
import {
  Td,
  DraftStatus,
  PendingStatus,
  ProcessingStatus,
  FailedStatus,
  PartiallyPaidStatus,
  PaidStatus,
  TooltipDiv,
  TooltipContent,
  ManageIcon,
} from './Table.styles';
import { Payroll, PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { reopenPayroll } from 'lib/reopenPayroll';
import { toast } from 'react-toastify';
import { formatName } from 'lib/utils/formatName';
import { formatDate } from 'lib/utils/formatDate';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'components/Icon/icon';
import InfoPlaceholder from 'components/Placeholder/Info';

export type TableProps = {
  payrolls: Payroll[];
  onDelete: (providerId: string) => void;
  status?: PayrollStatus;
};

export const Table = ({ payrolls, onDelete, status }: TableProps) => {
  const navigate = useNavigate();
  const [isTooltipVisible, setIsTooltipVisible] = useState<string | null>(null);
  const manageIconRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      manageIconRef.current &&
      !manageIconRef.current.contains(event.target as Node) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setIsTooltipVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (payrolls.length === 0) {
    if (status) {
      return <InfoPlaceholder title="No Submitted Payrolls" image="tucker" />;
    } else {
      return <EmptyMessage>No payrolls found. Please check back later.</EmptyMessage>;
    }
  }

  const handleReOpenPayroll = async (providerId: string) => {
    try {
      await reopenPayroll({ providerId });
      toast.success('Payroll has been reopened.');
      navigate('/payroll/run-payroll/hours', {
        state: { PayrollProviderId: providerId },
      });
    } catch (error) {
      toast.error('Unable to reopen payroll');
    }
  };

  const handleToggleTooltip = (providerId: string) => {
    setIsTooltipVisible((prev) => (prev === providerId ? null : providerId));
  };

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Payday</Th>
          <Th>Pay Period</Th>
          <Th>Type</Th>
          <Th>Status</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {payrolls.map((payroll) => (
          <Tr key={payroll.providerId}>
            <Td>{formatDate(payroll.payday)}</Td>
            <Td>{`${formatDate(payroll.periodStart)} - ${formatDate(payroll.periodEnd)}`}</Td>
            <Td>{formatName(payroll.type)}</Td>
            <Td>
              {payroll.status === PayrollStatus.Draft && (
                <DraftStatus>{payroll.status}</DraftStatus>
              )}
              {payroll.status === PayrollStatus.Pending && (
                <PendingStatus>{payroll.status}</PendingStatus>
              )}
              {payroll.status === PayrollStatus.Processing && (
                <ProcessingStatus>{payroll.status}</ProcessingStatus>
              )}
              {payroll.status === PayrollStatus.Failed && (
                <FailedStatus>{payroll.status}</FailedStatus>
              )}
              {payroll.status === PayrollStatus.PartiallyPaid && (
                <PartiallyPaidStatus>{formatName(payroll.status)}</PartiallyPaidStatus>
              )}
              {payroll.status === PayrollStatus.Paid && <PaidStatus>{payroll.status}</PaidStatus>}
            </Td>
            <Td>
              <div ref={manageIconRef}>
                <ManageIcon>
                  <Icon name="manage" onClick={() => handleToggleTooltip(payroll.providerId)} />
                </ManageIcon>

                {isTooltipVisible === payroll.providerId && (
                  <TooltipDiv ref={tooltipRef}>
                    {payroll.status === PayrollStatus.Draft && (
                      <TooltipContent>
                        <Button
                          onClick={() =>
                            navigate('/payroll/run-payroll/hours', {
                              state: { PayrollProviderId: payroll.providerId },
                            })
                          }
                        >
                          Edit Payroll
                        </Button>
                        <Button onClick={() => onDelete(payroll.providerId)}>Delete Payroll</Button>
                      </TooltipContent>
                    )}
                    {payroll.status === PayrollStatus.Pending && (
                      <Button onClick={() => handleReOpenPayroll(payroll.providerId)}>
                        Re-Open Payroll
                      </Button>
                    )}
                  </TooltipDiv>
                )}
              </div>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
